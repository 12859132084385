import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
// import { navigate } from '@reach/router'
import { callApi } from "../../utils/auth"
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

function Redirect(props) {
  const codeLength = props.location.search.length
  const code = props.location.search.slice(6, codeLength)
  const [passConfirm, setPassConfirm] = React.useState("")
  const [pass, setPass] = React.useState("")
  const [errorMsg, setErrorMsg] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMsg(null)
    setLoading(false)
    try {
      const response = await callApi(`/auth/reset-password`, "POST", {
        code: code,
        password: pass,
        passwordConfirmation: passConfirm,
      })
      if (response.user) {
        navigate("/profile")

        sessionStorage.setItem("token", JSON.stringify(response.jwt))
      } else {
        setLoading(true)
        console.log(response.data[0].messages[0].message)
        // throw "Can't login, Please try again"
        setErrorMsg(response.data[0].messages[0].message)
      }
    } catch (err) {
      setErrorMsg(err)
    }
  }

  return (
    <Wrapper>
      {props.location.search.length > 20 && (
        <div className="main_wrapper">
          <h2>Reset Password</h2>
          {errorMsg}
          <div className="search__container">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="pass"
              label="password"
              name="password"
              autoComplete="password"
              value={pass}
              InputLabelProps={{
                style: {
                  color: "#151515",
                  opacity: "0.8",
                  fontFamily: "var(--family)",
                  fontWeight: "500",
                },
              }}
              onChange={(e) => setPass(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmPass"
              label="confirm password"
              name="confirm pass"
              autoComplete="confirm pass"
              value={passConfirm}
              InputLabelProps={{
                style: {
                  color: "#151515",
                  opacity: "0.8",
                  fontFamily: "var(--family)",
                  fontWeight: "500",
                },
              }}
              onChange={(e) => setPassConfirm(e.target.value)}
            />
          </div>
          <button
            onClick={handleSubmit}
            disabled={pass && passConfirm ? false : true}
          >
            {loading ? "submit" : "loading.."}
          </button>
        </div>
      )}
    </Wrapper>
  )
}

export default Redirect
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    @media (max-width: 767px) {
      width: 90%;
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 30px 0;
      @media (max-width: 479px) {
        font-size: 1rem;
      }
    }
  }
  .search__container {
    display: flex;
    flex-direction: column;

    width: 50%;
    @media (max-width: 767px) {
      width: 90%;
    }
  }
  button {
    min-width: 200px;
    background: var(--thirdColor);
    padding: 15px 25px;
    font-size: 1rem;
    outline: none;
    font-weight: 500;
    border-radius: 60px;
    margin-top: 20px;
    cursor: pointer;
  }
  button[disabled] {
    cursor: unset;
  }
`
